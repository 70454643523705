import React from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { Button } from "bootstrap";
import logo from "../../assets/images/logo.svg";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          <Link to={"/"}>Home</Link>
          {/* <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/digital-agency"}>Home</Link></li>
                        {/* <li><Link to={process.env.PUBLIC_URL + "/creative-agency"}>Creative Agency</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>Personal Portfolio</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home Startup</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Corporate Agency</Link></li>
                        <li><a href="https://new.axilthemes.com/demo/react/abstrak-rtl/">RTL Demo</a></li> */}
          {/* </ul> */}
        </li>
        <li className="menu-item-has-children">
          <Link to={"/services"}>
            Services <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link to={"/services#Design"}>Design</Link>
            </li>
            {/* <li><Link to={process.env.PUBLIC_URL + "/service-one"}>Design</Link></li> */}
            <li>
              <Link to={"/services"}>Development</Link>
            </li>
            <li>
              <Link to={"/services"}>Marketing</Link>
            </li>
            <li>
              <Link to={"/services"}>Business</Link>
            </li>
            <li>
              <Link to={"/services"}>Technology</Link>
            </li>
            <li>
              <Link to={"/services"}>Video Production</Link>
            </li>
          </ul>
        </li>
        {/* <li className="menu-item-has-children">
          <Link to={"/portfolio"}>Portfolio</Link>
        </li> */}
        <li className="menu-item-has-children">
          <Link to={"/about-us"}>About us</Link>
        </li>

        <li>
          <Link to={"/contact"}>Contact</Link>
        </li>
        <div className="header-action">
          <ul className="list-unstyled">
            <li className="header-btn">
             
              <a
                href="https://wa.me/9030367334"
                target="_blank"
                className="axil-btn btn-fill-primary"
                style={{
                  color: "white",
                  background: "none",
                  border: "1px solid white",
                }}
              >
                Say Hello
              </a>
            </li>
          </ul>
        </div>
      </ul>
    </nav>
  );
};

export default Nav;
