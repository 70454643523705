import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import "./Banner.css";

const BannerTwo = () => {
  return (
    <div className="banner banner-style-1 bg-color-dark-2">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg">
            <div className="banner-content hero-section      ">
              {/* <h1 className="title" style={{textAlign:'center'}}>Technology &amp; design studio</h1> */}
              <p style={{ color: "White" }}>
                We helped startups to achieve their goals.
              </p>
              <h1
                className="title"
                style={{
                  textAlign: "center",
                  lineHeight: "1.2",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                Let's Build Your
                <br /> Next <span className="span_big"> Big Idea</span>
              </h1>
              <div className="button-grp">
                {" "}
                <Link
                  to={process.env.PUBLIC_URL + "/portfolio"}
                  className="axil-btn btn-fill-secondary btn-small .header-btn"
                  style={{ backgroundColor: "#fcc31e", color: "#111111" }}
                >
                  Our Work
                </Link>
                <a
                  href="https://wa.me/9030367334"
                  target="_blank"
                  className="about-btn"
                >
                  Say Hello 👋
                </a>
              </div>
             
             
              {/* <img src={process.env.PUBLIC_URL + "/images/banner/123.png"}/> */}
             
            </div>
          </div>
        

        </div>
      </div>
      <ul className="list-unstyled shape-group-1">
        <li className="shape shape-3">
          <AnimationOnScroll
            animateIn="slideInRight"
            duration={1}
            animateOnce={true}
            delay={100}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-2.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
      </ul>
      {/* <ul className="list-unstyled shape-group-9">
                <li className="shape shape-1">
                <AnimationOnScroll  animateIn="slideInRight" duration={1} animateOnce={true} delay={100}>
                    <img src={process.env.PUBLIC_URL + "/images/banner/ae.webp"} alt="Shape" />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-2">
                <AnimationOnScroll  animateIn="slideInLeft" duration={1} animateOnce={true}>
                    <img src={process.env.PUBLIC_URL + "/images/banner/edit.png"} alt="Shape" />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-3">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Shape" />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-4">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                    <img src={process.env.PUBLIC_URL + "/images/others/ps.webp"} alt="Shape" />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-5">
                <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Shape" />
                </AnimationOnScroll>
                </li>
                <li className="shape shape-6">
                    <AnimationOnScroll  animateIn="zoomIn" duration={1} animateOnce={true} delay={500}>
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"} alt="Shape" />
                    </AnimationOnScroll>
                </li>
                <li className="shape shape-7">
                    <AnimationOnScroll  animateIn="slideInDown" duration={1} animateOnce={true} delay={500}>
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-26.png"} alt="Shape" />
                    </AnimationOnScroll>
                </li>
            </ul> */}
    </div>
  );
};

export default BannerTwo;
