import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass} bg-color-dark`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link">
            <ul className="list-unstyled">
              <li>
                <Link
                  to="https://www.facebook.com/profile.php?id=100090756235276"
                  target="_blank"
                >
                  <FaFacebookF />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/Krowls3" target="_blank">
                  <FaTwitter />
                </Link>
              </li>
              <li>
                <Link
                  to="https://in.pinterest.com/krowlsdesign/"
                  target="_blank"
                >
                  <FaPinterestP />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/company/krowls/"
                  target="_blank"
                >
                  <FaLinkedin />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/krowls.design/"
                  target="_blank"
                >
                  <FaInstagram />
                </Link>
              </li>
              {/* <li><Link to="https://vimeo.com/"><FaVimeoV /></Link></li> */}
              <li>
                <Link to="https://dribbble.com/krowls" target="_blank">
                  <FaDribbble />
                </Link>
              </li>
              <li>
                <Link to="https://www.behance.net/krowls" target="_blank">
                  <FaBehance />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">Get in touch!</h2>
                  <p>Please Get In Touch by registering your email here...!</p>
                  {/* <form>
                                        <div className="input-group bg-color-primary" >
                                            <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                            <input type="email" className="form-control" placeholder="Email address" />
                                            <button className="subscribe-btn" type="submit">Subscribe</button>
                                        </div>
                                    </form> */}
                  <Link
                    to={process.env.PUBLIC_URL + "/portfolio"}
                    className="axil-btn btn-fill-secondary btn-small .header-btn"
                    style={{ backgroundColor: "#fcc31e", color: "#111111" }}
                  >
                    Download Profile
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Services</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {getServiceData.slice(0, 6).map((data, index) => (
                          <li key={index}>
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                `/service-details/${slugify(data.title)}`
                              }
                            >
                              {data.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Resourses</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/case-study"}>Case Studies</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/project-grid-one"}>Portfolio</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">Support</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={"/portfolio"}>Portfolio</Link>
                        </li>
                        <li>
                          <Link to={"/contact"}>Contact</Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={"/privacy-policy"}>Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to={"/terms-use"}>Terms of Use</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="https://www.krowls.com/">Krowls</a>.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                      Terms of Use
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
