import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectFive from '../component/project/ProjectFive';
import ProjectOne from '../component/project/ProjectOne';
import ProjectTwo from '../component/project/ProjectTwo';
import ProjectThree from '../component/project/ProjectThree';
import ProjectFour from '../component/project/ProjectFour';



const ProjectGridFour = () => {

    return (
        <>
        <SEO title="Project Full Width Three Column" />
        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            
            <HeaderOne />
            <BcrumbBannerOne 
                title="Our Projects - Updating soon"
                paragraph ="Here is a brief example of how we use design thinking and strategy to help you achieve your big dream.
                "
                styleClass=""
                mainThumb="/images/banner/banner-thumb-1.png"
            />
            {/* <ProjectOne />
            <CtaLayoutOne /> */}
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ProjectGridFour;