import React from "react";
import "./branditem.css"

const Data = [
  {
    image: "/images/clients/akila.png",
  },
  {
    image: "/images/clients/alessio.png",
  },
  {
    image: "/images/clients/etc.png",
  },
  {
    image: "/images/clients/frescobar.png",
  },
  {
    image: "/images/clients/muvitaab.png",
  },
  {
    image: "/images/clients/vge.png",
  },
  {
    image: "/images/clients/ttm.png",
  },
  {
    image: "/images/clients/HIT.png",
  },
];

const BrandItem = () => {
  return (
    <>
      {Data.map((data, index) => (
        <div className="col-lg-3 col-6" key={index}>
          <div className="brand-grid">
            <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandItem;
