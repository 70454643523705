import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Creative",
        para: "We have a team of experienced designers, writers, and strategists who can help you develop creative and effective marketing campaigns that will reach your target audience."
     },
    {
        id: 2,
        title: "Experienced",
        para:"We have worked with a wide range of clients, from small businesses to Fortune 500 companies. We have a deep understanding of the marketing landscape and the latest trends."
    },
    {
        id: 3,
        title: "Reliable",
        para:"We are committed to providing our clients with the best possible service. We listen to your needs and goals, and we work with you to develop a creative and effective marketing strategy that meets your specific requirements."
    },
    {
        id: 4,
        title: "Affordable",
        para: "We offer a variety of services at a range of price points. We are committed to finding a solution that fits your budget."
    },
    {
        id: 5,
        title: "Results-oriented",
        para: "We are committed to helping our clients achieve their marketing goals. We track the results of our campaigns and make adjustments as needed to ensure that you are getting the most out of your investment."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20 bg-color-dark-2">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Values"
                    title="Why should you work with us?"
                    description=" We have a team of experienced designers, writers, and strategists who can help you develop creative and effective marketing campaigns that will reach your target audience."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;